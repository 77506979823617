import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

export const StyledButton = styled.button`
  border-radius: 1rem;
  border: none;
  background-color: #ffdf3f;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  font-size: 1rem;
  color: #354348;
  cursor: pointer;
  :hover {
    background-color: #ffef4f;
  }
`;

export const MainTitle = styled.span`
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const NoBreak = styled.span`
  word-break: keep-all;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const CountDown = styled.div`
  text-align:center;
  font-size: 3rem;
  padding-top: 1rem;
  color: #ffef4f;
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`;

export const HideInMobile = styled.span`
  @media (max-width: 600px) {
    display: none;
    word-break: break-all !important;
  }
`;

export const StyledLogo = styled.img`
  width: auto;
  max-width: 260px;
  @media (max-width: 768px) {
    max-width: 50%;
  }
`;

export const StyledImg = styled.img`
  max-width: 95%;
`;

export const StyledOpenSea = styled.img`
  height: 2rem;
`;

export const SpacerImg = styled.img`
  width: 50%;
  height: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const StyledLink = styled.a`
  color: #ffdf3f;
  text-decoration: none;
`;

export const HelpLink = styled.a`
  color: #ffdf3f;
  text-decoration: none;
  cursor: help;
`;

function App() {
  const calculateTimeLeft = () => {
    let difference =
      +new Date(`April 26, 2022 12:00:00 GMT+08:00`) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        天: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(1, '0'),
        時: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
        分: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
        秒: Math.floor((difference / 1000) % 60).toString().padStart(2, '0'),
      };
    }

    return timeLeft;
  };

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimedNft, setClaimedNft] = useState(false);
  const [claimNftError, setClaimNftError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [errorMsg, setErrorMsg] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 300000,
    GAS_FEE: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    MARKETPLACE_LINK: "",
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmount);
    console.log("Cost: ", totalCostWei);
    setClaimingNft(true);
    setClaimNftError(false);
    setClaimedNft(false);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: CONFIG.GAS_LIMIT,
        gasPrice: CONFIG.GAS_FEE,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setErrorMsg(`Error ${err.code}: ${err.message}`);
        setClaimNftError(true);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setClaimedNft(true);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <s.Screen>
      {showModal ? (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={toggleModal}>&times;</span>
          <s.TextTitle>玩法說明</s.TextTitle>
          <ol>
            <li>MIF 使用 Polygon 作為發行鏈</li>
            <li>每次 MINT 需要，1 Matic (未包含 Gas Fee)</li>
            <li>如把所持有之 MIF 於 OpenSea 轉帳至 0xAFAD72332D450fb045f5a26D489A645A3FFaBA2F，將獲得 0.8 Matic 之退款</li>
            <li>每個錢包地址，於持有 500 MIF 後，將不能再進行 MINT</li>
            <li>MIF 約於 2022 年 4 月 24 日 00:00:00 (GMT+8) 開始進行 MINT</li>
            <li>MIF 以盲盒方法上市</li>
            <li>MIF 盲盒將於2022年4月內，因銷售情況進行簡單而隆重的開盒儀式，同時將可能同時關閉 MINT 功能</li>
            <li>盲盒內之 MIF 將於開盒儀式進時上傳，所以無人可於開盒前得知盲盒內容</li>
          </ol>
        </div>
      </div>
      ): null}
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#354348" }}
        image={"/images/bg.png"}
      >
        <s.Container flex={2} jc={"center"} ai={"center"}>
          <s.SpacerMedium />
          <StyledLogo alt={"logo"} src={"/images/logo.png"} />
          <s.SpacerMedium />
          <s.TextTitle>
            <MainTitle>
              <NoBreak>Man In Fat</NoBreak>
              <HideInMobile>｜</HideInMobile>
              <NoBreak>
                混血肥仔
                <NoBreak>
                  <span style={{ color: "#ffdf3f" }}>純粹玩下</span>計劃
                </NoBreak>
              </NoBreak>
            </MainTitle>
          </s.TextTitle>
          <s.SpacerMedium />
          {!timerComponents.length ? (
            <>
              <s.SpacerMedium />
              <s.Container ai={"center"} jc={"center"}>
                <s.TextTitle>已經停左 MINT 喇，如果仲想要就請去 OpenSea 睇啦。</s.TextTitle>
                <s.SpacerSmall />
                <StyledImg alt={"logo"} src={"/images/refresh.png"} />
                <s.SpacerSmall />
                MINT 左既記得去 OpenSea Refresh metadata 開箱喇
              </s.Container>
              <s.SpacerMedium />
            </>
          ) : (
            <>
            <s.SpacerMedium />
            <s.TextTitle>關 MINT 倒數，請把握最後時間</s.TextTitle>
            <CountDown>{timerComponents}</CountDown>
            <s.SpacerMedium />
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextSubTitle
                    style={{ textAlign: "center", color: "#fff" }}
                  >
                    請先連接至 {CONFIG.NETWORK.NAME} 錢包
                  </s.TextSubTitle>
                  <s.SpacerSmall />
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    連接 MetaMask
                  </StyledButton>
                </s.Container>
              ) : (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextTitle>
                    已鑄造 {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                  </s.TextTitle>
                  <s.SpacerSmall />
                  {Number(data.totalSupply) < CONFIG.MAX_SUPPLY ? (
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                        getData();
                      }}
                    >
                      {claimingNft ? "請等等" : "十蚊跟機"}
                    </StyledButton>
                  ) : (
                    <s.TextTitle style={{ textAlign: "center" }}>
                      已經 Mint 哂喇，想要就去下面OpenSea睇下有冇人賣啦！
                    </s.TextTitle>
                  )}
                </s.Container>
              )}
              {claimedNft ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "#ffdf3f",
                    }}
                  >
                    你已經Mint到一個Man in fat, a plan from Mixed fat boy, JUST for fun啦，快啲去下面OpenSea入面睇下啦！
                  </s.TextDescription>
                </s.Container>
              ) : null}
              {blockchain.errorMsg !== "" ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    {blockchain.errorMsg}
                  </s.TextDescription>
                </s.Container>
              ) : null}
              {claimNftError ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    請注意每個戶口持有{CONFIG.MAX_MINT}
                    個MIF之後就唔可以再Mint架喇，想要多啲就去OpenSea買啦!
                    <br />
                    {errorMsg}
                  </s.TextDescription>
                </s.Container>
              ) : null}
            </>
          )}
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center" }}>
            為左玩下野，我推出6,000隻肥仔NFT{" "}
            <StyledLink target="_blank" href={CONFIG.SCAN_LINK}>
              MIF
            </StyledLink>
          </s.TextDescription>
          <s.SpacerXSmall />
          <s.TextDescription style={{ textAlign: "center" }}>
            <span style={{ color: "#ffdf3f", fontWeight: "bold" }}>
              用十蚊 (1 Matic) 跟機好喇
            </span>
          </s.TextDescription>
          <s.SpacerMedium />
          <HelpLink onClick={toggleModal}>玩法說明</HelpLink>
          <s.SpacerMedium />
          <StyledLink target="_blank" href={CONFIG.MARKETPLACE_LINK}>
            <StyledOpenSea src={"/images/opensea.png"} />
          </StyledLink>
          <s.SpacerMedium />
          <SpacerImg src={"/images/bg_spacer.png"} />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
