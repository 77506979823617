import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 0.5rem;
  width: 0.5rem;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 1rem;
  width: 1rem;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 1.5rem;
  width: 1.5rem;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 2rem;
  width: 2rem;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom;
  @media (max-width: 768px) {
    background-size: 80%;
  }
`;

export const TextTitle = styled.p`
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: #fff;
  font-size: 1rem;
  line-height: 1.6;
`;
